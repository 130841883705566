<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="bDialogUpdatePriceRawMaterial"
    :width="screenWidth + '%'"
  >
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-update-price-raw-material">
          <p class="title-update-price-raw-material">
            Actualización de precios
          </p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-text-fields">
          <div class="content-current-base-price">
            <div class="content-title">
              <p class="p-title">Precio base</p>
            </div>
            <div class="content-text">
              <p class="p-text">
                ${{ formatMoneyGlobal(dBasePriceMXN) }}
                <span class="content-divisa">MXN</span>
              </p>
            </div>
            <div class="content-text">
              <p class="p-text">
                ${{ formatMoneyGlobal(dBasePriceUSD) }}
                <span class="content-divisa">USD</span>
              </p>
            </div>
          </div>
          <div class="content-current-base-price">
            <div class="content-title">
              <p class="p-title">Precio personalizado actual</p>
            </div>
            <div class="content-text">
              <p class="p-text">
                ${{ formatMoneyGlobal(dVendorPriceMXN) }}
                <span class="content-divisa">MXN</span>
              </p>
            </div>
          </div>
          <div class="content-current-base-price">
            <div class="content-title">
              <p class="p-title">Precio personalizado actual</p>
            </div>
            <div class="content-text">
              <p class="p-text">
                ${{ formatMoneyGlobal(dVendorPriceUSD) }}
                <span class="content-divisa">USD</span>
              </p>
            </div>
          </div>
          <v-text-field-currency
            v-model="dUnitPriceMXN"
            class="mb-5"
            outlined
            label="Nuevo precio personalizado (MXN)"
            placeholder="0.00"
            :bImportantData="true"
            :options="oOptionCurrencyMXNGlobal"
          />
          <v-text-field-currency
            v-model="dUnitPriceUSD"
            outlined
            label="Nuevo precio personalizado (USD)"
            placeholder="0.00"
            :bImportantData="true"
            :options="oOptionCurrencyUSDGlobal"
          />
         
        </div>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-sidebar-global mb-3"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              class="global-btn-primary"
              :disabled="bAdd"
              :loading="bLoading"
              @click="updatePrice()"
            >
              Actualizar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    itemRawMaterial: Object,
    bDialogUpdatePriceRawMaterial: Boolean,
  },
  data() {
    return {
      screenWidth: 0,
      bAdd: true,
      bLoading: false,
      dBasePrice: 0,
      dVendorPriceMXN: 0,
      dVendorPriceUSD: 0,
      dUnitPriceMXN: 0,
      dUnitPriceUSD: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 60;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 40;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 30;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 25;
      }
    },
    setDialogUpdatePriceRawMaterial() {
      this.$emit("setDialogUpdatePriceRawMaterial");
    },
    close() {
      this.setDialogUpdatePriceRawMaterial();
      this.dUnitPriceMXN = 0;
      this.dUnitPriceUSD = 0;
      this.bAdd = true;
      this.bLoading = false;
    },
    updatePrice() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminProvider) {
            this.changePrice();
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    changePrice() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminProvider) {
            const payload = {
                dUnitPriceMXN: parseFloat(this.dUnitPriceMXN),
              },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.patch(
              `${URI}/api/sp/v1/vendors/enterprises/${this.$route.params.id}/materials/${this.itemRawMaterial.sRawMaterialId}`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;
                this.$store.commit("refresher", true);
                this.close();
                this.Success(response.data.message);
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    validateForm() {
      this.bAdd = this.dUnitPriceMXN < 0 && this.dUnitPriceUSD < 0;
    },
  },
  computed: {
    dialogUpdatePriceRawMaterial() {
      return this.$store.state.dialogUpdatePriceRawMaterial;
    },
    bShowProvider() {
      return this.$store.state.bShowProvider;
    },
    bAdminProvider() {
      return this.$store.state.bAdminProvider;
    },
  },
  watch: {
    bDialogUpdatePriceRawMaterial() {
      if (this.bDialogUpdatePriceRawMaterial) {
        // this.dBasePrice = this.itemRawMaterial.dBasePrice;
        // this.dVendorPriceMXN = this.itemRawMaterial.dVendorPriceMXN;
      }
    },
    dUnitPriceMXN() {
      if (this.dUnitPriceMXN === null) {
        this.dUnitPriceMXN = 0;
      }
      this.validateForm();
    },
  },
};
</script>
<style scoped>
.content-title-update-price-raw-material {
  width: 100%;
}

.title-update-price-raw-material {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}

.content-current-base-price {
  /* display: flex; */
  width: 100%;
  margin-bottom: 15px;
}

.content-title {
  justify-content: start;
  margin-bottom: 0px;
  /* width: 50%; */
}

.p-title {
  text-align: start;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-bottom: 0px;
}
.content-text {
  /* width: 50%; */
}
.p-text {
  text-align: start;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-right: 5px;
}
.content-divisa {
  margin-left: 10px;
  color: var(--primary-color-text-yellow);
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}
.content-btn-primary {
  width: 100%;
}
.content-btn-second {
  width: 100%;
  margin-right: 15px;
}

.content-btns-picker-color {
  width: 50%;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
